export const ApiLinks = {
  PROJECTS: {
    ALL: '/projects',
    PROJECT: '/projects/:projectId',
    CONFIG: '/projects/:projectId/config',
    EPICS: '/projects/:projectId/epics',
    UPDATE: '/projects/:projectId/update',
    RATES: {
      ALL: '/projects/:projectId/rates',
      RATE: '/projects/:projectId/rates/:rateId',
    },
  },
  FORECAST: {
    COMPONENTS_STATS: '/forecast/:projectId/stats',
  },
  AUTH: {
    LOGIN: '/auth/login',
  },
} as const;
