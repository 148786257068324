import UserRole from '../types/UserRole.enum';
import { assert } from '../utils/assert';
import { getAuthData } from '../utils/authDataStorage';
import { LoaderFunction } from 'react-router-dom';

export const unauthorizeUserClient: LoaderFunction = () => {
  const session = getAuthData();
  const isNotRoleClient = !(
    (session?.role !== undefined &&
      session?.role !== null &&
      ![UserRole.OWNER, UserRole.MANAGER].includes(session?.role)) ||
    session?.role === UserRole.CLIENT
  );
  assert(isNotRoleClient, 'Error: You are not authorized to view this.');
  return session;
};
