import CircularLoader from '../ui/CircularLoader';
import HeaderSkeleton from './HeaderSkeleton';
import { FC } from 'react';

const PageSkeleton: FC = () => (
  <div className="h-[100vh] w-[100vw] overflow-hidden">
    <HeaderSkeleton />
    <div className="flex h-full w-full items-center justify-center">
      <CircularLoader />
    </div>
  </div>
);

export default PageSkeleton;
