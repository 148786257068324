export const DynamicPaths = {
  PROJECT_ID: ':projectId',
} as const;

const Paths = {
  HOME: '/',
  SIGN_IN: '/sign-in',
  PROJECTS: '/projects',
  PROJECT: {
    INDEX: `/projects/${DynamicPaths.PROJECT_ID}`,
    SPRINTS: 'sprints',
    CONFIG: {
      INDEX: 'config',
      BUDGET: 'budget',
      RATES: 'rates',
    },
    METRICS: 'metrics',
  },
  NOT_FOUND: '*',
} as const;

export const getProjectPath = (projectId: string) =>
  Paths.PROJECT.INDEX.replace(DynamicPaths.PROJECT_ID, projectId);

export default Paths;
