import { redirectLogin } from './api/redirectLogin';
import { redirectProjects } from './api/redirectProjects';
import { signIn } from './api/signInAction';
import { unauthorizeUserClient } from './api/unauthorizeUserClientLoader';
import { getUserInfo } from './api/utils';
import PageSkeleton from './components/skeletons/PageSkeleton';
import Paths from './utils/Paths';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter([
  {
    id: 'root',
    path: Paths.HOME,
    loader: getUserInfo,
    lazy: () => import('./components/layouts/Layout'),
    children: [
      {
        index: true,
        loader: redirectProjects,
      },
      {
        id: 'project',
        path: Paths.PROJECT.INDEX,
        lazy: () => import('./components/layouts/ProjectLayout'),
        children: [
          {
            index: true,
            lazy: () => import('./pages/ProjectPage'),
          },
          {
            id: 'sprints',
            path: Paths.PROJECT.SPRINTS,
            loader: unauthorizeUserClient,
            lazy: () => import('./pages/UnderDevelopmentPage'),
          },
          {
            id: 'metrics',
            path: Paths.PROJECT.METRICS,
            loader: unauthorizeUserClient,
            lazy: () => import('./pages/MetricsPage'),
          },
          {
            path: Paths.PROJECT.CONFIG.INDEX,
            loader: unauthorizeUserClient,
            lazy: () => import('./pages/ProjectConfigPage'),
            children: [
              {
                id: 'project-update',
                index: true,
                lazy: () => import('./pages/ProjectUpdatePage'),
              },
              {
                id: 'budget',
                path: Paths.PROJECT.CONFIG.BUDGET,
                lazy: () => import('./pages/BudgetPage'),
              },
              {
                id: 'rates',
                path: Paths.PROJECT.CONFIG.RATES,
                lazy: () => import('./pages/RatesPage'),
              },
            ],
          },
        ],
      },
      {
        path: Paths.PROJECTS,
        lazy: () => import('./pages/ProjectsListPage'),
      },
      {
        path: Paths.NOT_FOUND,
        lazy: () => import('./pages/NotFoundPage'),
      },
    ],
  },
  {
    path: Paths.SIGN_IN,
    lazy: () => import('./pages/SignInPage'),
    loader: redirectLogin,
    action: signIn,
  },
]);

export const Router = () => (
  <RouterProvider
    router={router}
    fallbackElement={<PageSkeleton />}
    future={{ v7_startTransition: true }}
  />
);
