import { User } from '../schemas/User.schema';
import Paths from '../utils/Paths';
import { getAuthData } from '../utils/authDataStorage';
import axios from 'axios';
import { redirect } from 'react-router-dom';

export const apiClient = axios.create({
  baseURL: import.meta.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use((config) => {
  const session = getAuthData();
  if (session?.access_token) {
    config.headers.Authorization = `Bearer ${session.access_token}`;
  }
  return config;
});

type GetUserInfo = () => Pick<User, 'access_token' | 'username'>;
export const getUserInfo: GetUserInfo = () => {
  const session = getAuthData();
  if (!session?.access_token) throw redirect(Paths.SIGN_IN);
  return { access_token: session.access_token, username: session.username };
};
