import Container from '../ui/Container';
import Logo from '../ui/Logo';
import { Skeleton } from '../ui/Skeleton';
import { FC } from 'react';

const HeaderSkeleton: FC = () => (
  <div
    className="w-full flex-initial bg-brand shadow-md"
    data-testid="header-skeleton"
  >
    <Container className="flex h-16 items-center justify-between gap-2 py-3 sm:gap-4 md:gap-8">
      <Logo />
      <Skeleton className="h-full flex-1 bg-contrast/10" />
      <Skeleton className="flex h-full w-64 flex-col items-center gap-x-2 bg-contrast/10 text-contrast lg:flex-row lg:gap-4"></Skeleton>
    </Container>
  </div>
);

export default HeaderSkeleton;
