import { toast } from '../components/ui/Toaster/useToast';
import { ZodError } from 'zod';

export default function handleRequestError(error: unknown) {
  if (error instanceof ZodError) {
    toast({
      variant: 'destructive',
      title: 'Response parsing error',
      description: JSON.parse(error.message)[0]?.message,
    });
  } else if (error instanceof Error) {
    if (error.name === 'AbortError') return;
    if (error.name === 'AxiosError') {
      toast({
        variant: 'destructive',
        title: 'Request error',
        description: error.message,
      });
      return;
    }

    toast({
      variant: 'destructive',
      title: error.name,
      description: error.message,
    });
  } else {
    toast({
      variant: 'destructive',
      title: 'Something went wrong!',
      description: 'Unknown error',
    });
  }
}
