import logo from '../../assets/images/logo.png';
import { FC } from 'react';

const Logo: FC = () => (
  <img
    alt="logo"
    src={logo}
    height="40"
    width="140"
    className="aspect-[50/40] max-w-[40px] object-cover object-left md:aspect-[140/40] md:max-w-[140px]"
  />
);

export default Logo;
