import { Slot } from '@radix-ui/react-slot';
import { HTMLAttributes, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

export type ContainerProps = HTMLAttributes<HTMLDivElement> & {
  asChild?: boolean;
};

const Container = forwardRef<HTMLDivElement, ContainerProps>(
  ({ className, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'div';
    return (
      <Comp
        className={twMerge('mx-auto w-full max-w-7xl px-2 md:px-4', className)}
        ref={ref}
        {...props}
      />
    );
  },
);
Container.displayName = 'Container';

export default Container;
