import { FC } from 'react';
import { ClassNameValue, twMerge } from 'tailwind-merge';

type SkeletonProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'className'> & {
  className?: ClassNameValue;
};

const Skeleton: FC<SkeletonProps> = ({ className, ...props }) => (
  <div
    data-testid="skeleton"
    className={twMerge('animate-pulse rounded bg-primary/10', className)}
    {...props}
  />
);

export { Skeleton };
