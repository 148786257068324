import { ApiLinks } from '../constants/apiLinks';
import NestedKeyOf from '../types/NestedKeyOf';

export type ApiLinkKey = NestedKeyOf<typeof ApiLinks>;

export type UriComponent = string | number | boolean;

/**
 * ExtractRouteParams is a utility type that extracts route parameters from a given string.
 * It uses template literal types to recursively parse the string and create a record of the parameters.
 *
 * @template T - The string from which to extract parameters.
 *
 * @returns If T is a string, it returns a Record with string keys and UriComponent values.
 * If T is a template literal with a parameter and a rest (e.g., '/path/:param/rest'), it returns a Record with keys being the parameter and the keys of the rest of the path, and values being UriComponent.
 * If T is a template literal with just a parameter (e.g., '/path/:param'), it returns a Record with the parameter as key and UriComponent as value.
 * If T is none of the above, it returns an empty object.
 */
type ExtractRouteParams<T> = string extends T
  ? Record<string, UriComponent>
  : T extends `${infer _Start}:${infer Param}/${infer Rest}`
    ? { [K in Param | keyof ExtractRouteParams<Rest>]: UriComponent }
    : T extends `${infer _Start}:${infer Param}`
      ? { [K in Param]: UriComponent }
      : {};

/**
 * Lookup is a utility type that allows to access deeply nested properties in an object.
 * It uses template literal types to recursively traverse the object.
 *
 * @template T - The object in which to look up the property.
 * @template K - The key of the property to look up, represented as a string. Nested properties can be accessed using dot notation (e.g., 'prop1.prop2.prop3').
 *
 * @returns If K is a template literal with a first part and a rest (e.g., 'first.rest'), and the first part is a key of T, it returns the type of the rest of the path in the property of T indicated by the first part.
 * If K is a key of T, it returns the type of the property of T indicated by K.
 * If K is none of the above, it returns never.
 */
type Lookup<T, K extends string> = K extends `${infer F}.${infer R}`
  ? F extends keyof T
    ? T[F] extends Record<string, unknown>
      ? Lookup<T[F], R>
      : never
    : never
  : K extends keyof T
    ? T[K]
    : never;

/**
 * LookupReturn is a utility type that extends the functionality of the Lookup type.
 * It uses conditional types to infer the return type of the Lookup function and checks if it's a string.
 *
 * @template T - The object in which to look up the property.
 * @template K - The key of the property to look up, represented as a string. Nested properties can be accessed using dot notation (e.g., 'prop1.prop2.prop3').
 *
 * @returns If the inferred return type of the Lookup function is a string, it returns that string, otherwise it returns never.
 */
type LookupReturn<T, K extends string> = Lookup<T, K> extends infer R
  ? R extends string
    ? R
    : never
  : never;

/**
 * The lookup function is a utility function that allows to access deeply nested properties in an object.
 * It uses template literal types to recursively traverse the object.
 *
 * @param {T} obj - The object in which to look up the property.
 * @param {K} key - The key of the property to look up, represented as a string. Nested properties can be accessed using dot notation (e.g., 'prop1.prop2.prop3').
 *
 * @returns {LookupReturn<T, K>} - If the inferred return type of the Lookup function is a string, it returns that string, otherwise it returns never.
 */
function lookup<T, K extends string>(obj: T, key: K): LookupReturn<T, K> {
  const [first, ...rest] = key.split('.');
  const value = obj[first as keyof typeof obj];

  if (rest.length && typeof value === 'object' && value !== null)
    return lookup(value, rest.join('.') as K);

  return value as LookupReturn<T, K>;
}

/**
 * The getApiLink function is a utility function that constructs a URL for an API endpoint.
 * It uses the provided key to lookup the corresponding path in the ApiLinks object, and replaces any parameters in the path with their corresponding values from the params object.
 *
 * @param {T} to - The key of the API endpoint in the ApiLinks object.
 * @param {ExtractRouteParams<Lookup<typeof ApiLinks, T>>} params - An object containing the values for any parameters in the path.
 *
 * @returns {URL} - The constructed URL for the API endpoint.
 */
export function getApiPath<T extends ApiLinkKey>(
  to: T,
  params: ExtractRouteParams<Lookup<typeof ApiLinks, T>>,
): string {
  let path: string = lookup(ApiLinks, to);

  for (const key in params) {
    path = path.replace(
      `:${key}`,
      encodeURIComponent(params[key] as UriComponent),
    );
  }

  return path;
}
