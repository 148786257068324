import UserRole from '../types/UserRole.enum';
import { z } from 'zod';

export const UserSchema = z.object({
  access_token: z.string().or(z.null()),
  username: z.string().or(z.null()),
  role: z.nativeEnum(UserRole).or(z.null()),
  organisation: z
    .object({
      host: z.string().or(z.null()),
    })
    .or(z.null()),
});

export type User = z.infer<typeof UserSchema>;
