import { UserSchema } from '../schemas/User.schema';
import { Credentials } from '../types/Credentials.type';
import Paths from '../utils/Paths';
import { saveAuthData } from '../utils/authDataStorage';
import { getApiPath } from './getApiPath';
import handleRequestError from './handleRequestError';
import { apiClient } from './utils';
import { ActionFunction, redirect } from 'react-router-dom';

export const signIn: ActionFunction = async ({ request }) => {
  const credentials: Credentials = await request.json();

  try {
    const response = await apiClient.post(
      getApiPath('AUTH.LOGIN', {}),
      credentials,
    );

    const user = UserSchema.parse(response.data);

    saveAuthData(user);
    return redirect(Paths.PROJECTS);
  } catch (error) {
    handleRequestError(error);
  }
};
