import * as ToastPrimitives from '@radix-ui/react-toast';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { twMerge } from 'tailwind-merge';

const ToastProvider = ToastPrimitives.Provider;

const ToastViewport = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Viewport>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Viewport
    ref={ref}
    className={twMerge(
      `fixed top-0 z-[100] flex max-h-screen w-full flex-col-reverse p-4
      sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col md:max-w-[420px]`,
      className,
    )}
    {...props}
  />
));
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;

const toastVariants = cva(
  `group pointer-events-auto relative flex w-full items-center justify-between
  space-x-2 overflow-hidden rounded border p-4 pr-6 shadow-lg transition-all
  data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)]
  data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none
  data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out
  data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full
  data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full`,
  {
    variants: {
      variant: {
        default: 'border bg-white text-text',
        destructive: 'destructive bg-danger text-white fill-white',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

const Toast = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Root> &
    VariantProps<typeof toastVariants>
>(({ className, variant, ...props }, ref) => (
  <ToastPrimitives.Root
    ref={ref}
    className={twMerge(toastVariants({ variant }), className)}
    {...props}
  />
));
Toast.displayName = ToastPrimitives.Root.displayName;

const ToastAction = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Action>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Action>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Action
    ref={ref}
    className={twMerge(
      `hover:bg-secondary focus:ring-ring inline-flex h-8 w-auto items-center
      justify-center truncate rounded border bg-transparent px-3 text-sm font-medium
      text-white transition-colors group-[.destructive]:border-white
      group-[.destructive]:hover:border-danger/30 group-[.destructive]:hover:bg-danger/10
      focus:outline-none focus:ring-1 group-[.destructive]:focus:ring-danger
      disabled:pointer-events-none disabled:opacity-50`,
      className,
    )}
    {...props}
  />
));
ToastAction.displayName = ToastPrimitives.Action.displayName;

const ToastClose = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Close>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Close>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Close
    ref={ref}
    className={twMerge(
      `absolute right-1 top-1 rounded p-1 text-text/50
     group-[.destructive]:text-white
       hover:text-text group-[.destructive]:hover:text-white
       focus:outline-none focus:ring-1 group-[.destructive]:focus:ring-white
       group-[.destructive]:focus:ring-offset-white`,
      className,
    )}
    toast-close=""
    {...props}
  >
    <AiOutlineClose className="h-4 w-4" />
  </ToastPrimitives.Close>
));
ToastClose.displayName = ToastPrimitives.Close.displayName;

const ToastTitle = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Title>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Title>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Title
    ref={ref}
    className={twMerge('text-sm font-semibold [&+div]:text-xs', className)}
    {...props}
  />
));
ToastTitle.displayName = ToastPrimitives.Title.displayName;

const ToastDescription = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Description>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Description>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Description
    ref={ref}
    className={twMerge('text-sm opacity-90', className)}
    {...props}
  />
));
ToastDescription.displayName = ToastPrimitives.Description.displayName;

type ToastProps = React.ComponentPropsWithoutRef<typeof Toast>;

type ToastActionElement = React.ReactElement<typeof ToastAction>;

export {
  type ToastProps,
  type ToastActionElement,
  ToastProvider,
  ToastViewport,
  Toast,
  ToastTitle,
  ToastDescription,
  ToastClose,
  ToastAction,
};
